import { getKcContext, KcContextBase } from "keycloakify/lib/getKcContext";
import { kcContextCommonMock } from "keycloakify/lib/getKcContext/kcContextMocks";

type LoginPage = { pageId: "login.ftl" };
type LoginCardIdPage = { pageId: "login-card-id-username.ftl" };
type LoginSMSPage = {
  pageId: "login-sms.ftl";
  mobile_number: string;
  otpState: "STATE_DISPLAY" | "STATE_REQUESTED";
  otpRetryIn: string;
  refCode: string;
};

type EbizPWDExpirePage = {
  pageId: "ebiz-pwd-expire.ftl";
  resetPasswordUrl: string;
};

type EBizPwdUpdatePage = {
  pageId: "ebiz-pwd-update.ftl";
  resetPasswordUrl: string;
};

const locale = {
  supported: [
    {
      languageTag: "th",
      label: "ไทย",
      url: "/auth/realms/myrealm/login-actions/authenticate?client_id=account&tab_id=HoAx28ja4xg&execution=ee6c2834-46a4-4a20-a1b6-f6d6f6451b36&kc_locale=th",
    },
  ],
  currentLanguageTag: "th",
};

kcContextCommonMock.realm.name = "aagi";
kcContextCommonMock.realm.internationalizationEnabled = true;
kcContextCommonMock.realm.registrationEmailAsUsername = false;
kcContextCommonMock.client.clientId = "aagi-app";
kcContextCommonMock.client.name = "AAGI App";

const mockLoginContext: any = kcContextCommonMock;
mockLoginContext.realm.loginWithEmailAllowed = false;

export const { kcContext } = getKcContext<
  // NOTE: A 'keycloakify' field must be added
  // in the package.json to generate theses extra pages
  // https://docs.keycloakify.dev/build-options#keycloakify.extrapages
  | LoginPage
  | LoginCardIdPage
  | LoginSMSPage
  | EbizPWDExpirePage
  | EBizPwdUpdatePage
>({
  mockPageId: "login.ftl",
  mockData: [
    {
      pageId: "info.ftl",
      locale,
      message: {
        type: "success",
      },
    },
    {
      pageId: "error.ftl",
      locale,
      message: {
        type: "error",
      },
    },
    {
      pageId: "login.ftl",
      locale,
      realm: {
        ...mockLoginContext.realm,
      },
      message: {
        type: "error",
      },
      social: {
        displayInfo: true,
        providers: [
          /* {
            displayName: "Microsoft",
            providerId: "microsoft",
            loginUrl: "/realms/aagi-int/broker/microsoft/login",
            // iconClasses: "fa fa-windows",
            alias: "microsoft",
          }, */
          {
            displayName: "Employee Login",
            providerId: "oidc",
            loginUrl:
              "/realms/aagi-int/broker/oidc/login?client_id=aagi-sso&tab_id=xbNtQ_gMuG0&session_code=S_IvD1G2eiraKNSzhHaEzY05JovpsBaUEuYQiqXdg48",
            // iconClasses: "",
            alias: "oidc",
          },
        ],
      },
    },
    {
      pageId: "login-username.ftl",
      locale,
    },
    {
      pageId: "login-card-id-username.ftl",
      message: {
        type: "info",
      },
      locale,
    },
    {
      pageId: "login-sms.ftl",
      locale,
      message: {
        type: "error",
        summary: "smsAuthCodeInvalid",
      },
      mobile_number: "08X-XXX-2212",
      // otpState: 'STATE_DISPLAY',
      otpState: "STATE_REQUESTED",
      otpRetryIn: (() => {
        const d = new Date();
        d.setSeconds(d.getSeconds() + 30);
        return `${d.getTime()}`;
      })(),
      refCode: "xXxo",
    },
    {
      pageId: "ebiz-pwd-expire.ftl",
      locale,
      resetPasswordUrl: "https://eportal-dev.allianz.co.th/forget-password",
      message: {
        type: "error",
        summary: "expiredPassword",
      },
    },
    {
      pageId: "ebiz-pwd-update.ftl",
      locale,
      message: {
        type: "error",
        summary: "passwordNotMeetingPolicy",
      },
    },
  ],
});

export type KcContext = NonNullable<typeof kcContext>;
